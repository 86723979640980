.welcome {
    text-align: center;
    background-color: rgb(0, 0, 0, 0.65) !important;
    border-radius: 20px;
    width: 35rem;
    padding: 30px;
    color: white;
    order: 1;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.welcomeTitle {
    color: white;
    margin-bottom: 2rem;
}

.playButton {
    margin-top: 2.5rem;
}
