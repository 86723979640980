
html {
  background: #041227;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #041227;
  height: 100%;
  overflow: hidden;
}

.star {
  background-color: #fff;
  position: absolute;
  border-radius: 100%;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  z-index: -100;
}

@-webkit-keyframes move_right {
  from {
    -webkit-transform: rotate(0deg) translateX(8px) rotate(0deg);
            transform: rotate(0deg) translateX(8px) rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg) translateX(8px) rotate(-360deg);
            transform: rotate(360deg) translateX(8px) rotate(-360deg);
  }
}

@keyframes move_right {
  from {
    -webkit-transform: rotate(0deg) translateX(8px) rotate(0deg);
            transform: rotate(0deg) translateX(8px) rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg) translateX(8px) rotate(-360deg);
            transform: rotate(360deg) translateX(8px) rotate(-360deg);
  }
}

@-webkit-keyframes move_left {
  from {
    -webkit-transform: rotate(0deg) translateX(8px) rotate(0deg);
            transform: rotate(0deg) translateX(8px) rotate(0deg);
  }

  to {
    -webkit-transform: rotate(-360deg) translateX(8px) rotate(360deg);
            transform: rotate(-360deg) translateX(8px) rotate(360deg);
  }
}

@keyframes move_left {
  from {
    -webkit-transform: rotate(0deg) translateX(8px) rotate(0deg);
            transform: rotate(0deg) translateX(8px) rotate(0deg);
  }

  to {
    -webkit-transform: rotate(-360deg) translateX(8px) rotate(360deg);
            transform: rotate(-360deg) translateX(8px) rotate(360deg);
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body {
  margin: 20px;
  background: none;
}

.welcome {
    text-align: center;
    background-color: rgb(0, 0, 0, 0.65) !important;
    border-radius: 20px;
    width: 35rem;
    padding: 30px;
    color: white;
    -webkit-order: 1;
            order: 1;
    position: fixed;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
}

.welcomeTitle {
    color: white;
    margin-bottom: 2rem;
}

.playButton {
    margin-top: 2.5rem;
}

.listGroup {
    background-color: transparent;
    width: 35em;
    position: fixed;
}

.cardMember {
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
}

.cardMember:hover {
    -webkit-transform: scale(1.12);
            transform: scale(1.12);
}

