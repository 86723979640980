
html {
  background: #041227;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #041227;
  height: 100%;
  overflow: hidden;
}

.star {
  background-color: #fff;
  position: absolute;
  border-radius: 100%;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  z-index: -100;
}

@keyframes move_right {
  from {
    transform: rotate(0deg) translateX(8px) rotate(0deg);
  }

  to {
    transform: rotate(360deg) translateX(8px) rotate(-360deg);
  }
}

@keyframes move_left {
  from {
    transform: rotate(0deg) translateX(8px) rotate(0deg);
  }

  to {
    transform: rotate(-360deg) translateX(8px) rotate(360deg);
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
